import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

import Slider from 'react-slick'
import '../styles/slick.css'

if (typeof window !== 'undefined') {
  // Make scroll behavior of internal links smooth
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]')
}

const Wrapper = styled.section`
  position: relative;
  min-height: 300px;
  margin-left: 10px;
  margin-right: 10px;
`

const HeaderWrapper = styled.div`
  width: 100%;
  position: relative;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  animation-duration: 1s;
  animation-delay: 0;
  margin: 0 auto;
  margin-top: 95px;
`

const ContentWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation-duration: 1s;
  animation-delay: 0;
  margin: 0 auto;
  margin-top: 0;
  background: #000;
  padding: 40px;
  text-align: center;
  p {
    color: white;
    font-size: 16px;
    margin-bottom: 1rem;
  }
`

const SlideWrapper = styled.div`
  position: relative;
  display: block;
  outline: none !important;
`

const Slide = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background: white;
  height: ${props => props.height};

  @media (min-width: 36em) {
    height: 100vh;
  }

  img {
    object-fit: ${props => props.fit || 'cover'} !important;
    object-position: ${props => props.position || '50% 50%'} !important;
    width: ${props =>
      props.vertical === true ? 'auto !important' : '100% !important'};
    height: ${props =>
      props.vertical === true ? '100% !important' : '100% !important'};
    @media (min-width: ${props => props.theme.responsive.medium}) {
      height: ${props =>
        props.vertical === true ? '95% !important' : '100% !important'};
    }

    @media (min-width: ${props => props.theme.responsive.veryLarge}) {
      width: auto !important;
      height: 95% !important;
    }
  }
  &::before {
    content: '';
    background: ${props =>
      props.visible === true ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)'};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    transition: background-color 500ms linear;
  }
`

class HeroHeader extends React.Component {
  render() {
    const settings = {
      draggable: true,
      arrows: false,
      dots: false,
      speed: 600,
      infinite: true,
      touchThreshold: 100,
      slidesToShow: 1,
      slidesToScroll: 1,
    }

    var images = this.props.posts

    return (
      <Wrapper>
        <HeaderWrapper>
          <Slider {...settings} ref={this.sliderRef}>
            {images.map((projectFiles, i) => (
              <SlideWrapper key={i}>
                <Slide
                  fluid={projectFiles.node.mobilePhoto.photo.fluid}
                  vertical={projectFiles.node.mobilePhoto.photo.vertical}
                  imgStyle={{
                    width: 'inherit',
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translateX(-50%) translatey(-50%)',
                    background: 'white',
                  }}
                />
              </SlideWrapper>
            ))}
          </Slider>
        </HeaderWrapper>
        <ContentWrapper>
          <p>
            This is the mobile version of this website. For the full version
            with more images please use your desktop computer.
          </p>
          <p>
            <strong>
              Maurice van der Meijs (+31) 62 454 67 68
              info@mauricevandermeijs.com
            </strong>
          </p>
        </ContentWrapper>
      </Wrapper>
    )
  }
}
export default HeroHeader
