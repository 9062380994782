import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const Post = styled.li`
  position: relative;
  margin: 0 0 1em 0;
  width: 100%;
  transition: background 0.2s;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 49%')};
    margin: 0 0 2vw 0;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 32%')};
  }
  &:hover {
    background: ${props => props.theme.colors.tertiary};
  }
  a {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    color: ${props => props.theme.colors.base};
    text-decoration: none;
    text-align: center;
    &:hover{
      .gatsby-image-wrapper{
        img{
          transform: scale(1.1);
        }
      }
    }
    .gatsby-image-wrapper {
      height: 0;
      padding-bottom: 60%;
      max-width: 100%;
      @media screen and (min-width: ${props => props.theme.responsive.small}) {
        padding-bottom: ${props => (props.featured ? '40%' : '60%')};
      }
      img{
        transition: all 1s !important;
      }
    }
  }
`
const ImageLink = styled(Link)`  
  width: 100%;
  height: 100%;
  transition: all 0.1s;

  span{
    position: absolute;
    color: white;
    z-index: 4;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    transition: all 0.1s;
    font-size: 1.2em;
  }
  &:hover{
    span{
      display: block;
    }
  }
  &:hover:after{
    content: '';
    z-index: 3;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
  }
  
`


const Card = ({ slug, heroImage, title, ...props }) => {
  return (
    <Post featured={props.featured}>
      <ImageLink to={`/${slug}/`} hex="#fff">
        <span>{title}</span>
        <Img fluid={heroImage.fluid} backgroundColor={'#eeeeee'} />
      </ImageLink>
    </Post>
  )
}

export default Card
