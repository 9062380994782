import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import CurrentProject from '../components/CurrentProject'

import arrowBottom from '../images/arrow-bottom.svg'

if (typeof window !== 'undefined') {
  // Make scroll behavior of internal links smooth
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]')
}

const Wrapper = styled.section`
  position: relative;
  min-height: 300px;
`

const HeaderWrapper = styled.div`
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-duration: 1s;
  animation-delay: 0;
`

const BackgroundHeaderImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  z-index: -1;
  height: ${props => props.height};
  @media (min-width: 36em) {
    height: 100vh;
  }
  // Adjust image positioning (if image covers area with defined height) and add font-family for polyfill
  & > img {
    object-fit: cover !important; // or whatever
    object-position: 0% 0% !important; // or whatever
    font-family: 'object-fit: cover !important; object-position: 0% 0% !important;'; // needed for IE9+ polyfill
  }
`

const ViewProjects = styled.a`
  position: absolute;
  bottom: 30px;
  @media (min-width: ${props => props.theme.responsive.medium}) {
    bottom: 30px;
  }
  color: #000;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1px;
  text-decoration: none;
  img {
    display: inline;
    margin: 0 5px 0 0;
    width: 18px;
  }
`
const HeaderTitleWrapper = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  text-align: center;
  width: 100%;
`

const HeaderTitle = styled.h1`
  margin: 0;
  font-size: 32px;
  @media (min-width: ${props => props.theme.responsive.medium}) {
    font-size: 42px;
  }
  @media (min-width: ${props => props.theme.responsive.large}) {
    font-size: 60px;
  }
  margin: 0;
  text-align: center;
  animation-duration: 1s;
  animation-delay: 1.2s;
  position: relative;
  color: black;
`

const HeaderSubtitle = styled.h4`
  margin: 0;
  font-size: 20px;
  @media (min-width: ${props => props.theme.responsive.medium}) {
    font-size: 24px;
    margin-top: 5px;
  }
  @media (min-width: ${props => props.theme.responsive.large}) {
    font-size: 28px;
  }
  animation-duration: 1s;
  animation-delay: 1.2s;
`

class HeroHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      height: '100vh',
    }
  }

  componentDidMount() {
    this.setState({
      height: window.innerHeight + 'px',
    })
  }
  render() {
    var projectPost =
      this.props.photoSet[1].photo.fluid != null
        ? this.props.photoSet[1].photo.fluid
        : this.props.photoSet[0].photo.fluid

    return (
      <Wrapper>
        <HeaderWrapper>
          <BackgroundHeaderImg
            // height={this.props.height}
            fluid={this.props.heroImage.fluid}
            backgroundColor={'#eeeeee'}
            height={this.state.height}
          />

          <HeaderTitleWrapper>
            <HeaderTitle data-text="Maurice van der Meijs">
              Maurice van der Meijs
            </HeaderTitle>
            <HeaderSubtitle>Photography</HeaderSubtitle>
          </HeaderTitleWrapper>

          <ViewProjects
            href="#work-list"
            className="animated pulse infinite slow delay-3s"
          >
            <img src={arrowBottom} alt="View Projects" />
            View Projects
          </ViewProjects>
          <CurrentProject slug={this.props.slug} projectPost={projectPost} />
        </HeaderWrapper>
      </Wrapper>
    )
  }
}
export default HeroHeader
