import React from 'react'
import styled from 'styled-components'

const FilterList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  // align-items: stretch;
  // justify-content: flex-start;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    flex-wrap: now-wrap;
  }

`

const FilterButton = styled.button`
  padding: 10px 20px;
  margin-right: 20px;
  font-size: 22px;
  color: #222;
  letter-spacing: 1.5px;
  border: ${props => props.primary ? '2px solid #222' : '2px solid transparent'};
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    border: 2px solid #222;
  }
  width: 100%;
  margin-bottom: 2px;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    width: auto;
  }


`

const Filters = props => {
  return (
    <FilterList>
      <FilterButton onClick={() => props.onChangeTag('All categories')}  primary={props.activeTag === 'All categories' ? true : false}>All</FilterButton>
      {props.tags.map(({ node: tag }) => (
        <FilterButton onClick={() => props.onChangeTag(tag.title)} primary={props.activeTag === tag.title ? true : false} key={tag.id}>{tag.title}</FilterButton>
      ))}
    </FilterList>
  )
}

export default Filters
