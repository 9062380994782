import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.section`
  margin: 0 auto auto;
  width: 100%;
  padding: 2em 1.5em 2em;
  max-width: 1400px;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    // display: flex;
    // padding: 3em 1em 2em;
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    // max-width: 50em;
    // padding: 3em 0.5em 2em;
  }

  @media screen and (min-width: ${props => props.theme.responsive.veryLarge}) {
    // max-width: 60em;
  }
  
`

const Container = props => {
  return <Wrapper>{props.children}</Wrapper>
}

export default Container
