import React from 'react'
import styled from 'styled-components'
import FirstCard from '../components/FirstCard'
import Card from '../components/Card'

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0 auto auto;
  width: 100%;
  // max-width: 1200px;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    padding: 0 0.5em 2em;
  }
  flex-grow: 1;

  &::after {
    content: '';
    flex: 0 0 32%;
  }
`

const CardList = props => {
  if(props.firstHeroImage === false){
    return (
      <List>
        {props.posts.map(({ node: post }) => (
          <Card key={post.id} {...post} />
        ))}
      </List>
    )
  } else{
    return (
      <List>
        {props.activeTag === 'All categories' && <FirstCard heroImage={props.firstHeroImage} />}
        {props.posts.map(({ node: post }) => (
          <Card key={post.id} {...post} />
        ))}
      </List>
    )
  }
}

export default CardList
