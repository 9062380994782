import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { useMediaQuery } from 'react-responsive'

//Config
import config from '../utils/siteConfig'

//Component
import Layout from '../components/Layout'
import CardList from '../components/CardList'
import HeroHeader from '../components/HeroHeader'
import HeroHeaderMobile from '../components/HeroHeaderMobile'
import SEO from '../components/SEO'
import Filters from '../components/FilterList'
import ContainerGrid from '../components/ContainerGrid'
import Lottie from 'lottie-react-web'

import animationSource from '../images/loading.json'
import arrowBottom from '../images/arrow-bottom.svg'

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 })
  return isDesktop ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}

const LottieWrapper = styled.div`
  background: black;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 14;
  top: 0;
  left: 0;
`

const HeaderWrapper = styled.div`
  margin: 0;
  width: 100%;
  flex-grow: 1;
`

const CardsHeader = styled.div`
  display: block;
  width: 100%;
  cursor: pointer;
  padding: 0.5em 0 1.5em 0em;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    padding: 0 0.5em 1.5em 0.5em;
  }
`
const CardsFilter = styled.h2`
  display: inline;
  font-size: 22px;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    font-size: 26px;
  }
  flex: 0 0 100%;
  padding-bottom: 10px;
  margin-bottom: 0;
`

const FilterLink = styled.div`
  display: inline-block;
  cursor: pointer;
`

const SelectedFilter = styled.div`
  font-size: 22px;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    font-size: 26px;
  }
  display: inline-block;
  color: #222;
  position: relative;
  margin-left: 10px;
`

const SelectedArrow = styled.img`
  display: inline-block;
  margin: 0 0 0 10px;
  width: 20px;
  transform: ${props => (props.showFilter ? 'rotate(180deg)' : '')};
`

function isfeaturedPost(post) {
  return post.node.featuredHeader === true
}

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showFilter: false,
      activeTag: 'All categories',
      animationShown: false,
      fadeClass: 'animated',
    }
    this.toggleFilters = this.toggleFilters.bind(this)
  }

  onAnimationComplete = () => {
    window.sessionStorage.setItem('animationShown', true)
    this.setState({
      animationShown: true,
    })
  }

  startAnimation = () => {
    this.animationRef.play()
    var menu = document.getElementById('lottiewrapper')
    menu.classList.add('fadeOut')
  }

  showAnimation = () => {
    if (typeof window !== `undefined`) {
      if (
        this.state.animationShown == false &&
        window.sessionStorage.getItem('animationShown') === null
      ) {
        return true
      } else {
        return false
      }
    }
  }

  toggleFilters() {
    this.setState(state => ({
      showFilter: !state.showFilter,
    }))
  }

  updateActiveTag = title => {
    this.setState({
      activeTag: title,
    })
  }

  filterPosts = posts => {
    if (this.state.activeTag === 'All categories') {
      return posts.filter(post =>
        post.node.tags.find(tag => tag.title !== 'Beer')
      )
    }

    return posts.filter(post =>
      post.node.tags.find(tag => tag.title === this.state.activeTag)
    )
  }

  render() {
    const data = this.props.data
    const pageContext = this.props.pageContext
    const posts = data.allContentfulWork.edges
    const mobilePosts = data.allContentfulMobilePhotos.edges
    const tags = data.allContentfulTag.edges
    const firstHeroImage = data.allContentfulAsset.edges[0].node
    const featuredPost = posts.find(isfeaturedPost)
    const { currentPage } = pageContext
    const isFirstPage = currentPage === 1

    const animationStyle = {
      background: 'black',
      position: 'absolute',
      height: '100%',
      width: '100%',
      zIndex: '14',
      top: '0',
      left: '0',
    }

    return (
      <Layout>
        <SEO />
        {this.showAnimation() && (
          <LottieWrapper id="lottiewrapper" className="animated delay-2s">
            <Lottie
              options={{
                autoplay: false,
                loop: false,
                animationData: animationSource,
              }}
              eventListeners={[
                {
                  eventName: 'DOMLoaded',
                  callback: () => this.startAnimation(),
                },
                {
                  eventName: 'complete',
                  callback: () => this.onAnimationComplete(),
                },
              ]}
              ariaRole="progressbar"
              style={animationStyle}
              ref={animationRef => {
                this.animationRef = animationRef
              }}
            />
          </LottieWrapper>
        )}

        {!isFirstPage && (
          <Helmet>
            <title>{`${config.siteTitle} - Page ${currentPage}`}</title>
          </Helmet>
        )}
        {isFirstPage ? (
          <HeaderWrapper>
            <Desktop>
              <HeroHeader {...featuredPost.node} />
              <ContainerGrid>
                <CardsHeader id="work-list" onClick={this.toggleFilters}>
                  <CardsFilter>Show Me: </CardsFilter>
                  <FilterLink>
                    <SelectedFilter>{this.state.activeTag}</SelectedFilter>
                    <SelectedArrow
                      src={arrowBottom}
                      alt="Filter work"
                      width="20"
                      showFilter={this.state.showFilter}
                    />
                  </FilterLink>
                  {this.state.showFilter && (
                    <Filters
                      tags={tags}
                      activeTag={this.state.activeTag}
                      onChangeTag={this.updateActiveTag}
                    />
                  )}
                </CardsHeader>

                <CardList
                  posts={this.filterPosts(posts)}
                  firstHeroImage={firstHeroImage}
                  activeTag={this.state.activeTag}
                />
              </ContainerGrid>
            </Desktop>
            <Mobile>
              <HeroHeaderMobile posts={mobilePosts} />
            </Mobile>
          </HeaderWrapper>
        ) : (
          <CardList posts={posts} firstHeroImage={false} />
        )}
      </Layout>
    )
  }
}

export const query = graphql`
  query WorkQuery($skip: Int!, $limit: Int!) {
    allContentfulWork(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          id
          featuredHeader
          slug
          publishDate(formatString: "MMMM DD, YYYY")
          tags {
            id
            title
            slug
          }
          photoSet {
            __typename
            ... on ContentfulWorkPhoto {
              vertical
              dark
              photo {
                fluid(maxWidth: 2000, quality: 60) {
                  ...GatsbyContentfulFluid_withWebp
                }
                file {
                  contentType
                  url
                }
              }
            }
          }
          heroImage {
            title
            fixed(width: 2200) {
              ...GatsbyContentfulFixed_withWebp_noBase64
            }
            fluid(maxWidth: 2200, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 80)
            }
          }
        }
      }
    }
    allContentfulTag {
      edges {
        node {
          title
          id
        }
      }
    }
    allContentfulMobilePhotos {
      edges {
        node {
          title
          id
          mobilePhoto {
            __typename
            ... on ContentfulWorkPhoto {
              vertical
              photo {
                fluid(maxWidth: 2000, quality: 60) {
                  ...GatsbyContentfulFluid_withWebp
                }
                file {
                  contentType
                  url
                }
              }
            }
          }
        }
      }
    }
    allContentfulAsset(
      filter: { contentful_id: { eq: "mkTw50ewuckgagEiaGaES" } }
    ) {
      edges {
        node {
          fluid(maxWidth: 2000, maxHeight: 1333, quality: 60) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`
export default Index
